import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const IndexPage = () => (
  <Layout>
    <SEO title="Submit a sign" />
    <h2>Submit a sign</h2>
    <p>Have you come across a sign in your neighbourhood that you want included in this collection? We'd love to have it! If you're taking photos:</p>
    <ul>
      <li>Try to take a picture as close to the sign as possible. Let it fill up most of the image</li>
      <li>Shoot it vertically</li>
      <li>Minimize reflections</li>
      <li>Take note of the business name and address</li>
    </ul>
    <p>We might crop the photo a bit but otherwise we'll post it as-is. You will retain the copyright but we will have the right to feature it in this collection and on our social media accounts.</p>
    <p className="submission-paragraph">
      <a onClick={e => {
        trackCustomEvent({
          category: "engagement",
          action: "start-submission"
        })
      }}
      className="button" href="https://docs.google.com/forms/d/e/1FAIpQLScK80OErQ5A8Ck9VW4rfvu02etdvEPxEI2pF2Z-qbc2znqkuw/viewform?usp=sf_link">Start Submission</a>
    </p>
    <p>If you do not want to submit through this form, please <a href="mailto:andrew@hyfen.net">email me</a>.</p>
  </Layout>
)

export default IndexPage
